import loader from "../src/assets/puff.svg";
import logo2 from "../src/assets/nanits_comics.png";
import code8logo from "../src/assets/logo_hero.png";
import epochaLogo from "../src/assets/logo-epocha.png";
import imao from "../src/assets/imao.png";
import storm from "../src/assets/storm.png";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo2} className="App-logo" alt="logo" />
        <div className="header">
          <p>You may not see it, but our hearts are still beating...</p>
        </div>
        <img src={loader} className="App-logo2" alt="logo" />
        <div className="message">
          <p>
            Our service is currently not 100% functional. We are moving our
            servers to a new location. We are working hard to get the service
            running again as soon as possible.
            <br />
            <br />
            We are also preparing something special for you. Stay in touch,
            we'll announce something great soon!
            <br />
            <br />
            You can still download our applications here{" "}
            <a
              className="App-link"
              href="https://itunes.apple.com/us/app/nanits-best-comic-book-reader/id1190101014?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              iOS
            </a>{" "}
            or here{" "}
            <a
              className="App-link"
              href="https://play.google.com/store/apps/details?id=com.nanitsuniverse.nanits&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Android
            </a>
            .
          </p>
          <br />
          <br />
          <h3 style={{ color: "#C9956D" }}>Companies that support us:</h3>
          <br />
          <div className="supporters-logos">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.code8.cz"
            >
              <img
                src={code8logo}
                className="supporter"
                style={{ height: 40, paddingBottom: 40 }}
                alt="logo"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.epocha.cz"
            >
              <img
                src={epochaLogo}
                className="supporter"
                style={{ height: 30, paddingBottom: 40 }}
                alt="logo"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.imao.cz"
            >
              <img
                src={imao}
                className="supporter"
                style={{ height: 30, paddingBottom: 40 }}
                alt="logo"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.stormit.cloud"
            >
              <img
                src={storm}
                className="supporter"
                style={{ height: 30, paddingBottom: 40 }}
                alt="logo"
              />
            </a>
          </div>
          <br />
          <h3 style={{ color: "#C9956D" }}>People who support us:</h3>
          <br />
          <div className="supporters-texts">
            <div className="supporter-text">Jaromír Novák,</div>
            <div className="supporter-text">Aleš Kozelka,</div>
            <div className="supporter-text">Pavel Mitošinka (Moredre),</div>
            <div className="supporter-text">Petr Mára,</div>
            <div className="supporter-text">Bára "Count de Worde" Smutná,</div>
            <div className="supporter-text">Slavomír Kandrik,</div>
            <div className="supporter-text">Tonál Had,</div>
            <div className="supporter-text">Bemiurg,</div>
            <div className="supporter-text">leebigh,</div>
            <div className="supporter-text">Verča a Mathy,</div>
            <div className="supporter-text">Lenka Kryková,</div>
            <div className="supporter-text">Michal Zobec,</div>
            <div className="supporter-text">Filip Kapitán,</div>
            <div className="supporter-text">Petr Diškant,</div>
            <div className="supporter-text">Pavel Paulo Mareš,</div>
            <div className="supporter-text">Petr Jalamudis,</div>
            <div className="supporter-text">Hana Repova</div>
          </div>
        </div>
      </header>
      <footer className="footer">
        <p>Nanits ® Universe Ltd. 2021 © All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
